<script>
import GetTrackingEvents from '@/api/trackingEvents/getTrackingEvents';
import PostTrackingEvents from '@/api/trackingEvents/postTrackingEvents';
import Loader from '@/components/Loader';

export default {
  name: 'AboutTA10',
  components: { Loader },
  data() {
    return {
      checked: false,
      lockedCheckbox: false,
      loader: false,
      confirmationEvent: { aboutTa6Confirmed: 'about_ta10_confirmed' },
    };
  },
  props: ['entityType', 'entityId'],
  methods: {
    async confirm() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }
      this.updateConfirmationStatus();
      return true;
    },
    async checkConfirmationStatus() {
      this.loader = true;
      const confirmationEvents = Object.values(this.confirmationEvent);
      await GetTrackingEvents(this.entityId, confirmationEvents)
        .then((response) => {
          let count = response.data.count;
          if (count > 0) {
            this.lockedCheckbox = true;
            this.checked = true;
          }
        })
        .catch((err) => console.debug(err));

      this.loader = false;
    },
    async updateConfirmationStatus() {
      let trackingEvent = {
        product_slug: this.entityType,
        entity_id: this.entityId,
        event_slug: this.confirmationEvent.aboutTa6Confirmed,
        metadata: '',
      };
      await PostTrackingEvents(trackingEvent)
        .then(() => {
          this.loader = false;
        })
        .catch((err) => console.debug(err));
    },
  },
  mounted() {
    this.checkConfirmationStatus();
  },
};
</script>

<template>
  <div class="row">
    <h2 class="col-md-12">About this Form</h2>
    <div v-if="loader">
      <loader />
    </div>
    <div v-else>
      <div else id="page-content" class="col-md-12">
        <div class="row border-bottom my-2">
          <h5 class="col-md-2 mb-1">About This Form:</h5>
          <ul class="col-md-10">
            <li>
              This form is completed by the seller to supply detailed information and documents which may be relied upon
              for the conveyancing process.
            </li>
          </ul>
        </div>

        <div class="row border-bottom my-2">
          <h5 class="col-md-2 mb-1">Definitions:</h5>
          <ul class="col-md-10">
            <li>“Seller” means all sellers together if the property is owned by more than one person.</li>
            <li>“Buyer” means all buyers together if the property is being bought by more than one person.</li>
            <li>“Property” includes all the buildings and land within the boundaries.</li>
          </ul>
        </div>

        <div class="row border-bottom my-2">
          <h5 class="col-md-2 mb-1">Instructions to the Seller:</h5>
          <ul class="col-md-10">
            <li>
              Answers should be prepared by the person or persons who are named as owner on the deeds or Land Registry
              title or by the owner’s legal representative(s) if selling under a power of attorney or grant of
              representation. If there is more than one seller, you should prepare the answers together or, if only one
              seller prepares the form, the other(s) should check the answers given and all sellers should sign the
              form.
            </li>
            <li>
              If you do not know the answer to any question, you must say so. If you are unsure of the meaning of any
              questions or answers, please ask your conveyancer. Completing this form is not mandatory, but omissions or
              delay in providing some information may delay the sale.
            </li>
            <li>
              If you later become aware of any information which would alter any replies you have given, you must inform
              your conveyancer immediately. This is as important as giving the right answers in the first place. Do not
              change any arrangements concerning the property with anyone (such as a tenant or a neighbour) without
              first consulting your conveyancer.
            </li>
            <li>
              It is very important that your answers are accurate. If you give incorrect or incomplete information to
              the buyer (on this form or otherwise in writing or in conversation, whether through your estate agent or
              conveyancer or directly to the buyer), the buyer may make a claim for compensation from you or refuse to
              complete the purchase.
            </li>
            <li>
              You should answer the questions based upon information known to you (or, in the case of legal
              representatives, you or the owner). You are not expected to have expert knowledge of legal or technical
              matters, or matters that occurred prior to your ownership of the property.
            </li>
            <li>
              Please give your conveyancer any letters, agreements or other papers which help answer the questions. If
              you are aware of any which you are not supplying with the answers, tell your conveyancer. If you do not
              have any documentation you may need to obtain copies at your own expense. Also pass to your conveyancer
              any notices you have received concerning the property and any which arrive at any time before completion
              of the sale.
            </li>
          </ul>
        </div>

        <div class="row border-bottom my-2">
          <h5 class="col-md-2 mb-1">Instructions to the Buyer:</h5>
          <ul class="col-md-10">
            <li>
              If the seller gives you, separately from this form, any information concerning the property (in writing or
              in conversation, whether through an estate agent or solicitor or directly to you) on which you wish to
              rely when buying the property, you should tell your conveyancer.
            </li>
            <li>
              You are entitled to rely on the replies given to enquiries but in relation to the physical condition of
              the property, the replies should not be treated as a substitute for undertaking your own survey or making
              your own independent enquiries, which you are recommended to do.
            </li>
            <li>
              The seller is only obliged to give answers based on their own information. They may not have knowledge of
              legal or technical matters. You should not expect the seller to have knowledge of, or give information
              about, matters prior to their ownership of the property.
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="form-group form-check my-1">
          <div class="col-md-12">
            <ValidationObserver ref="observer">
              <ValidationProvider name="confirmation" rules="confirmation" v-slot="{ errors }">
                <span class="form-text text-danger">{{ errors[0] }}</span>
                <b-form-checkbox
                  type="checkbox"
                  :disabled="lockedCheckbox"
                  v-model="checked"
                  id="confirmation"
                  class="form-check-input"
                >
                  I confirm that I have read and understand above information
                </b-form-checkbox>
              </ValidationProvider>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#page-content {
  height: 22rem;
  overflow-y: scroll;
  font-size: 1rem;
}

#page-content th {
  vertical-align: top;
}

.form-check-input {
  font-size: 1rem;
}
</style>
