<script>
import * as Rooms from '@sort-group/sort-room-fittings';
import { v4 as uuid } from 'uuid';
import modal from '@/components/Modal';
import roomBlock from '@/components/forms/rooms/RoomBlock';
import patchRooms from '@/api/buildings/patchRooms';
import { mapMutations, mapState } from 'vuex';
import * as roomsMutations from '@/store/rooms/mutations';
import differenceBy from 'lodash/differenceBy';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';

const customRoomName = 'custom';
const defaultSelectNoOptionSelected = 'Please choose an option';
const defaultRoomInputAlreadyExists = 'This title has been already used';
const roomInputEmpty = 'Please enter a room name';
const roomInputOnlyNumbers = 'Title must consist of letters';
const roomInputExistsInDefaultRooms = 'Room title exists as a default room';

export default {
  name: 'Rooms',
  components: { modal, roomBlock },
  props: {
    continueWithDefaultRooms: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => {
    return {
      saving: false,
      customRoomName,
      roomInput: '',
      selectedOption: '',
      showRoomInput: false,
      selectErrorMessage: null,
      roomInputErrorMessage: null,
      showModal: false,
    };
  },
  methods: {
    ...mapMutations([
      `${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_ADD_NEW}`,
      `${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_REMOVE_EXISTING}`,
      `${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_SET_ROOMS_DATA}`,
    ]),
    async saveToApi() {
      if (!this.s_buildingId) {
        return false;
      }

      this.saving = true;
      const response = await patchRooms(this.s_buildingId, this.rooms);

      this.saving = false;
      return response.status;
    },
    newRoomTitleAlreadyExists(capitalizedRoomInput) {
      for (let i = 0; i < this.rooms.length; i++) {
        if (this.rooms[i].title === capitalizedRoomInput) {
          return true;
        }
      }
      return false;
    },
    addRoom() {
      if (!this.roomInput) {
        this.roomInputErrorMessage = roomInputEmpty;
        return false;
      }
      if (/^\d+$/.test(this.roomInput)) {
        this.roomInputErrorMessage = roomInputOnlyNumbers;
        return false;
      }
      this.roomInput = this.roomInput.charAt(0).toUpperCase() + this.roomInput.slice(1);
      if (this.newRoomTitleAlreadyExists(this.roomInput)) {
        this.roomInputErrorMessage = defaultRoomInputAlreadyExists;
        return false;
      }
      if (this.customRoomNameExistsInDefaultRooms(this.roomInput)) {
        this.roomInputErrorMessage = roomInputExistsInDefaultRooms;
        return false;
      }
      this[`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_ADD_NEW}`]({
        roomId: uuid(),
        roomType: customRoomName,
        title: this.roomInput,
        fittings: [],
      });
      this.roomInput = '';
      this.roomInputErrorMessage = null;
      this.selectedOption = '';
      this.showRoomInput = !this.showRoomInput;
    },
    addDefaultRoom() {
      if (!this.selectedOption.roomType) {
        this.selectErrorMessage = defaultSelectNoOptionSelected;
        return false;
      }

      this[`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_ADD_NEW}`]({
        roomId: uuid(),
        roomType: this.selectedOption.roomType,
        title: this.selectedOption.title,
        fittings: [],
      });
      this.selectedOption = '';
    },
    customRoomNameExistsInDefaultRooms(capitalizedRoomInput) {
      for (let i = 0; i < this.defaultRooms.length; i++) {
        if (capitalizedRoomInput === this.defaultRooms[i].title) {
          return true;
        }
      }
      return false;
    },
    addRoomToSelect(room) {
      this.selectOptions.unshift({
        title: room.title,
        roomType: room.roomType,
        roomId: '',
      });
    },
    removeRoom(room) {
      if (room.roomType !== customRoomName) {
        this.addRoomToSelect(room);
      }
      this[`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_REMOVE_EXISTING}`](this.roomIndex(room));
    },
    onRoomSelectChange() {
      this.selectErrorMessage = null;
      this.roomInputErrorMessage = null;
      this.roomInput = '';
      if (this.selectedOption) {
        this.selectedOption.roomType === customRoomName ? (this.showRoomInput = true) : (this.showRoomInput = false);
      }
    },
    async showFittings(continueWithDefaultRooms) {
      if (this.optionalRooms.length === 0 && !continueWithDefaultRooms) {
        this.showModal = true;
        return false;
      }

      return this.saveToApi().then((res) => {
        this.continueWithDefaultRooms = false;
        return res === 200;
      });
    },
    goToFittings(value) {
      /*
      Triggered by modal event, if the value is true
      it will trigger an another event to let parent component
      know that the access to fittings with only default rooms selected is allowed
      */
      this.showModal = false;
      if (value) {
        this.$emit('goToFittingsPage');
      }
    },
    roomIndex(value) {
      return this.s_rooms.roomsData.findIndex((item) => value.title === item.title);
    },
  },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.building.buildingId,
      s_rooms: (state) => state.building.rooms,
    }),
    rooms: function () {
      const sorted = sortBy(this.s_rooms.roomsData, 'title');

      // Sort rooms with basic fittings at the start
      const fetchedRooms = [];
      sorted.forEach((value) => {
        if (value.roomType === 'basic_fittings') {
          fetchedRooms.unshift(value);
        } else {
          fetchedRooms.push(value);
        }
      });

      // Move optional rooms to the end
      fetchedRooms.forEach((value, index) => {
        if (value.roomType === 'custom') {
          fetchedRooms.splice(index, 1);
          fetchedRooms.push(value);
        }
      });

      return fetchedRooms;
    },
    defaultRooms: function () {
      const required = reduce(
        Rooms.DefaultRooms,
        (acc, value) => {
          if (value.default_selected) {
            acc[value.type] = value;
          }
          return acc;
        },
        {},
      );

      return map(required, (value, key) => {
        let roomId = uuid();
        if (this.rooms) {
          const existingRoom = find(this.rooms, (item) => lowerCase(item.name) === key);

          if (existingRoom) {
            roomId = existingRoom.roomId;
          }
        }

        return {
          roomType: value.type,
          title: value.name,
          roomId: roomId,
          fittings: [],
        };
      });
    },
    optionalRooms: function () {
      if (this.rooms) {
        return differenceBy(this.rooms, this.defaultRooms, 'title');
      }
      return [];
    },
    allRooms: function () {
      return reduce(
        Rooms.DefaultRooms,
        (acc, value) => {
          acc.push({
            roomType: value.type,
            title: value.name,
            roomId: '',
          });
          return acc;
        },
        [],
      );
    },
    selectOptions: function () {
      const diff = differenceBy(this.allRooms, [...this.defaultRooms, ...this.optionalRooms], 'title');
      const customRoom = { roomType: Rooms.RoomTypes.CUSTOM, title: 'Other', roomId: '' };
      const index = diff.findIndex((item) => item.roomType === customRoom.roomType);
      return index < 0 ? [...diff, customRoom] : diff;
    },
  },
  mounted() {
    if (this.s_rooms.roomsData !== null && this.s_rooms.roomsData.length === 0) {
      this[`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_SET_ROOMS_DATA}`](this.defaultRooms);
    }

    this.$emit('updateRooms', this.rooms);
  },
  updated() {
    this.$emit('updateRooms', this.rooms);
  },
};
</script>

<template>
  <div>
    <hr />
    <h2>Add Rooms</h2>
    <p>Please add all rooms of your property.</p>

    <div class="form-row">
      <div class="form-group col-10 col-md-5">
        <select
          @change="onRoomSelectChange"
          id="roomsSelect"
          name="rooms"
          v-model="selectedOption"
          class="form-control bg-white custom-select"
        >
          <option :selected="true" disabled="disabled" value="">Please choose</option>
          <option
            v-for="(option, index) in selectOptions"
            :key="index"
            :value="{ roomType: option.roomType, title: option.title }"
            :class="[option.roomType !== customRoomName || 'font-weight-bold']"
          >
            {{ option.title }}
          </option>
        </select>
        <small v-if="selectErrorMessage" class="form-text text-danger">{{ selectErrorMessage }}</small>
      </div>
      <div class="form-group col-2 col-md-1">
        <button
          v-if="!showRoomInput"
          type="submit"
          name="default-room"
          class="add-button rounded-circle btn btn-success mb-4 w-100"
          @click="addDefaultRoom"
        >
          +
        </button>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="showRoomInput" class="form-row mt-2">
        <div class="form-group col-md-3">
          <input v-model="roomInput" id="roomInput" maxlength="30" @keyup.enter="addRoom" class="form-control" />
          <small v-if="roomInputErrorMessage" id="roomInputErrorMessage" class="form-text text-danger">{{
            roomInputErrorMessage
          }}</small>
        </div>
        <div class="form-group col-md-2">
          <button type="submit" class="add-button rounded-circle btn btn-success mb-4" id="addRoom" @click="addRoom">
            +
          </button>
        </div>
      </div>
    </transition>

    <h2>Default Rooms</h2>

    <div class="row">
      <div class="col-md-12">
        <room-block :rooms="defaultRooms"></room-block>
      </div>
    </div>

    <div v-if="optionalRooms.length > 0">
      <hr />
      <h2>Other Rooms</h2>
      <div class="row">
        <div class="col-md-12">
          <room-block :rooms="optionalRooms" :deletable="true" @removeRoom="removeRoom"></room-block>
        </div>
      </div>
    </div>

    <modal v-if="showModal" closeButtonText="Add rooms" proceedButtonText="Continue" @proceed="goToFittings">
      <template slot="header">You have not added any additional rooms.</template>
      <p>
        Please ensure the list of rooms includes all rooms in the property. Please click "Continue" if you wish to
        proceed with only the standard rooms, otherwise click "Add Rooms" to amend the room list.
      </p>
    </modal>
  </div>
</template>

<style lang="scss" scoped>
.add-button {
  font-weight: 900;
}

.form-group {
  margin-bottom: 0;
}

.next-button-container {
  display: flex;
  justify-content: flex-end;
}

.next-button {
  min-width: 115px;
  margin-bottom: 5px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
