import { render, staticRenderFns } from "./RoomBlock.vue?vue&type=template&id=4198bbc8&scoped=true&"
import script from "./RoomBlock.vue?vue&type=script&lang=js&"
export * from "./RoomBlock.vue?vue&type=script&lang=js&"
import style0 from "./RoomBlock.vue?vue&type=style&index=0&id=4198bbc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4198bbc8",
  null
  
)

export default component.exports