<script>
import { mapState } from 'vuex';
import getPerson from '@/api/persons/getPerson';
import Loader from '@/components/Loader';
import isEqual from 'lodash/isEqual';

export default {
  name: 'PropertyAndClientDetails',
  components: { Loader },
  data() {
    return {
      clients: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      s_clientIds: (state) => state.sale.clientIds,
      s_address: (state) => state.building.address,
      s_titleDeedDeclared: (state) => state.sale.titleDeedDeclared,
      s_titleDeedAlternative: (state) => state.sale.titleDeedAlternative,
    }),
    propertyAddress: function () {
      const buildingDetails = this.s_address ? this.s_address : {};

      return {
        companyName: buildingDetails.organisation_name,
        buildingName: buildingDetails.premise,
        addressLine1: buildingDetails.thoroughfare,
        addressLine2: buildingDetails.dependent_locality,
        city: buildingDetails.locality,
        county: buildingDetails.sub_administrativeArea,
        country: buildingDetails.administrative_area,
        postcode: buildingDetails.postal_code,
      };
    },
    clientNames: {
      get() {
        const clientsDetails = this.clients ? this.clients : [];
        return clientsDetails.reduce((carry, item) => {
          carry.push([item.first_name, item.middle_name, item.last_name].join(' '));

          return carry;
        }, []);
      },
    },
  },
  methods: {
    fetchPersons: function () {
      this.loading = true;
      Promise.all(this.s_clientIds.map(async (id) => await getPerson(id))).then((result) => {
        result.forEach((item) => this.clients.push(item.data));
        this.loading = false;
      });
    },
  },
  watch: {
    s_clientIds(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.clients = [];
        this.fetchPersons();
      }
    },
  },
  mounted() {
    if (this.s_clientIds) {
      this.fetchPersons();
    }
  },
};
</script>

<template>
  <div>
    <loader v-if="loading"></loader>
    <div v-else class="row w-100">
      <h2 class="col-md-12 mb-3">Property & Client Details</h2>
      <div class="row w-100 my-2">
        <div class="col-md-3"><h5>Address of the Property:</h5></div>
        <div class="col-md-9">
          <ul class="list-unstyled">
            <li class="w-100 py-2 border-bottom" v-if="propertyAddress.companyName">
              <h5 class="col-md-4 d-inline-block">Company Name:</h5>
              <span class="col-md-6 d-inline-block">{{ propertyAddress.companyName }}</span>
            </li>
            <li class="w-100 py-2 border-bottom" v-if="propertyAddress.buildingName">
              <h5 class="col-md-4 d-inline-block">Building Name or Number:</h5>
              <span class="col-md-6 d-inline-block">{{ propertyAddress.buildingName }}</span>
            </li>
            <li class="w-100 py-2 border-bottom" v-if="propertyAddress.addressLine1">
              <h5 class="col-md-4 d-inline-block">Address Line 1:</h5>
              <span class="col-md-6 d-inline-block">{{ propertyAddress.addressLine1 }}</span>
            </li>
            <li class="w-100 py-2 border-bottom" v-if="propertyAddress.addressLine2">
              <h5 class="col-md-4 d-inline-block">Address Line 2:</h5>
              <span class="col-md-6 d-inline-block">{{ propertyAddress.addressLine2 }}</span>
            </li>
            <li class="w-100 py-2 border-bottom" v-if="propertyAddress.city">
              <h5 class="col-md-4 d-inline-block">Town/City:</h5>
              <span class="col-md-6 d-inline-block">{{ propertyAddress.city }}</span>
            </li>
            <li class="w-100 py-2 border-bottom" v-if="propertyAddress.county">
              <h5 class="col-md-4 d-inline-block">County:</h5>
              <span class="col-md-6 d-inline-block">{{ propertyAddress.county }}</span>
            </li>
            <li class="w-100 py-2 border-bottom" v-if="propertyAddress.country">
              <h5 class="col-md-4 d-inline-block">Country:</h5>
              <span class="col-md-6 d-inline-block">{{ propertyAddress.country }}</span>
            </li>
            <li class="w-100 py-2 border-bottom" v-if="propertyAddress.postcode">
              <h5 class="col-md-4 d-inline-block">Postcode:</h5>
              <span class="col-md-6 d-inline-block">{{ propertyAddress.postcode }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row w-100 py-2">
        <div class="col-md-3"><h5>Full Names of Seller(s):</h5></div>
        <div class="col-md-9">
          <div v-if="s_titleDeedDeclared === false">
            <h6 class="col">{{ s_titleDeedAlternative }}</h6>
          </div>
          <div v-else>
            <ul class="list-unstyled">
              <li v-for="(item, index) in clientNames" v-bind:key="index" class="col">
                <h6>{{ item }}</h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
