<script>
import { DefaultRooms } from '@sort-group/sort-room-fittings';
import SingleFitting from '@/components/forms/fitting/SingleFitting';
import { mapMutations, mapState } from 'vuex';
import patchRooms from '@/api/buildings/patchRooms';
import * as roomsMutations from '@/store/rooms/mutations';
import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';

export default {
  name: 'Fittings',
  components: { SingleFitting },
  data: () => {
    return {
      newFitting: '',
      addOtherFittingErrorMessage: null,
      roomTypes: DefaultRooms,
    };
  },
  props: {
    roomId: {
      title: String,
      required: true,
    },
    otherRooms: {
      title: String,
      required: false,
    },
    selected: {
      title: String,
      default: '',
    },
    roomName: {
      title: String,
      default: '',
    },
  },
  filters: {
    pascalToWords(word) {
      return startCase(word);
    },
  },
  methods: {
    ...mapMutations([
      `${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_ADD_FITTING}`,
      `${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_REMOVE_FITTING}`,
    ]),
    clearOtherFittingError() {
      this.addOtherFittingErrorMessage = null;
    },
    async saveToApi() {
      this.saving = true;
      await patchRooms(this.s_buildingId, this.fetchedRooms);
      this.saving = false;
    },
    addOtherFitting() {
      if (this.newFitting.length < 3) {
        let vm = this;
        this.addOtherFittingErrorMessage = 'The given fitting name should be minimum 3 characters length';
        setTimeout(() => {
          vm.addOtherFittingErrorMessage = null;
        }, 2500);
      }

      const title = upperFirst(this.newFitting.toLowerCase());
      if (this.selectedFittings.find((item) => item.title === title && item.fittingType === 'OTHER')) {
        let vm = this;
        this.addOtherFittingErrorMessage = 'The given fitting name is already on the list';
        setTimeout(() => {
          vm.addOtherFittingErrorMessage = null;
        }, 2500);
      } else if (this.newFitting.length > 2) {
        this[`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_ADD_FITTING}`]({
          roomId: this.roomId,
          value: {
            fittingType: 'OTHER',
            title: title,
            fitted: false,
            freeStanding: false,
            excluded: false,
            price: null,
            comments: null,
          },
        });

        this.newFitting = '';
      }
    },
    toggleFitting(key, fitting, roomId, e) {
      if (e) {
        this.addRoomFitting(key, fitting);
      } else {
        let indexFound = this.selectedFittings.findIndex((item) => item.title === fitting);
        this.removeFitting(roomId, indexFound);
      }
    },
    addRoomFitting(key, fitting) {
      this[`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_ADD_FITTING}`]({
        roomId: this.roomId,
        value: {
          fittingType: key,
          title: fitting,
          fitted: false,
          freeStanding: false,
          excluded: false,
          price: null,
          comments: null,
        },
      });
    },
    removeFitting(roomId, fittingIndex) {
      this[`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_REMOVE_FITTING}`]({
        roomId,
        fittingIndex,
      });
    },
    isRoomFittingSelected(key) {
      return this.selectedFittings && !!this.selectedFittings.find((item) => item.fittingType === key);
    },
  },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.building.buildingId,
      s_rooms: (state) => state.building.rooms,
      selectedFittings: function (state) {
        let fittings = [];
        const room = state.building.rooms.roomsData.find((item) => item.roomId === this.roomId);
        if (room) {
          fittings = room.fittings;
        }

        return fittings;
      },
    }),
    fetchedRooms: function () {
      return this.s_rooms.roomsData;
    },
    roomFittings: function () {
      const defaultRoom = Object.values(DefaultRooms).find((item) => item.type === this.selected);

      return (defaultRoom || DefaultRooms.BASIC_FITTINGS).fittings;
    },
  },
};
</script>

<template>
  <div>
    <h2>{{ roomName | pascalToWords }}</h2>

    <div class="border-top my-2"></div>

    <ValidationObserver v-slot="{ invalid }">
      <div class="row" v-for="(type, key) in roomFittings" :key="key">
        <div class="col-md-12">
          <h2 class="my-3">{{ key | pascalToWords }}</h2>
          <div class="d-flex d-flex flex-wrap">
            <div :key="fitting" class="form-check col-md-3" v-for="(fitting, key) in type">
              <b-form-checkbox
                class="form-check form-check-switchery"
                name="check-button"
                @change="toggleFitting(key, fitting, roomId, $event)"
                :checked="isRoomFittingSelected(key)"
                switch
              >
                {{ fitting }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-3">
        <h2 class="col-md-12 my-3">Add Other Fittings</h2>
        <p class="col-12 mb-4 d-table-row d-sm-none bg-light p-2">
          <strong>Notice: </strong>We've noticed you're using a device with a small screen (likely a mobile phone).
          While it's entirely possible to proceed, we do recommend a larger screen for this part of the process.
          Alternatively try using your device in landscape mode rather than portrait.
        </p>
        <div class="col-10 col-sm-6">
          <div class="input-group mb-3 w-100">
            <input
              @input="clearOtherFittingError"
              @keyup.enter="addOtherFitting"
              aria-describedby="add-button"
              aria-label="Other fittings"
              class="form-control"
              type="text"
              v-model="newFitting"
            />
          </div>
        </div>
        <div class="col-1">
          <div class="input-group-append form-group">
            <button
              @click="addOtherFitting"
              class="add-button rounded-circle btn btn-success mb-4 add-other-fitting"
              id="add-button"
              type="button"
            >
              +
            </button>
          </div>
          <small v-if="addOtherFittingErrorMessage" class="form-text text-danger">{{
            addOtherFittingErrorMessage
          }}</small>
        </div>
      </div>
      <!-- add other fitting button-->

      <!-- display selected fittings   -->
      <div class="col-md-12">
        <!-- display headers -->
        <div class="row pb-2">
          <div class="col-sm-12 col-md-6">
            <h5 class="d-flex justify-content-center justify-content-md-start">Fittings</h5>
          </div>
          <div class="d-none d-md-block col-md-1">
            <h5>Fitted</h5>
          </div>
          <div class="d-none d-md-block col-md-1">
            <h5>Excluded</h5>
          </div>
          <div class="d-none d-md-block col-md-1">
            <h5>Prices</h5>
          </div>
          <div class="d-none d-md-block col-md-3">
            <h5>Comments</h5>
          </div>
        </div>
        <!-- end display headers -->

        <single-fitting
          :key="index"
          v-for="(fitting, index) in selectedFittings"
          @fitting-removed="removeFitting"
          :value="fitting"
          :fitting-index="index"
          :room-id="roomId"
        />
      </div>
      <!-- /end display selected fittings -->
    </ValidationObserver>
  </div>
</template>

<style lang="scss">
.vertical-label {
  span {
    writing-mode: vertical-rl;
    display: block;
  }
}

.add-other-fitting {
  margin-left: 0.7rem;
}

.btn.btn-danger {
  min-width: 45px;
}

.single-fitting .custom-switch {
  margin-top: 0.7em;
}
</style>
