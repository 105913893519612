<script>
import Modal from '@/components/Modal';

export default {
  name: 'SaveProgressInfo',
  components: { Modal },
  data() {
    return {
      showInfo: true,
    };
  },
};
</script>

<template>
  <modal v-if="showInfo" closeButtonText="Close" @proceed="showInfo = false">
    <div class="text-center">
      <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
      <h3>Save and continue later</h3>
      <p>
        The current progress of completion is stored on a page by page basis. On each page you can save your progress
        and come back later.
      </p>
    </div>
  </modal>
</template>
