<script>
import { mapMutations, mapState } from 'vuex';
import * as roomsMutations from '@/store/rooms/mutations';

export default {
  name: 'SingleFitting',
  props: {
    roomId: {
      type: String,
      required: true,
    },
    fittingIndex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapMutations([`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_SET_FITTING}`]),
    removeFitting() {
      this.$emit('fitting-removed', this.roomId, this.fittingIndex);
    },
    updateFitting(value) {
      this[`${roomsMutations.ROOMS_PREFIX}${roomsMutations.ROOMS_SET_FITTING}`]({
        value: { ...this.s_fitting, ...value },
        roomId: this.roomId,
        fittingIndex: this.fittingIndex,
      });
    },
  },
  computed: {
    ...mapState({
      s_fitting: function (state) {
        return state.building.rooms.roomsData.find((room) => room.roomId === this.roomId).fittings[this.fittingIndex];
      },
    }),
    title: {
      get() {
        return this.s_fitting.title;
      },
    },
    fitted: {
      get() {
        return this.s_fitting.fitted;
      },
      set(value) {
        this.updateFitting({
          freeStanding: !value,
          fitted: value,
        });
      },
    },
    freeStanding: {
      get() {
        return this.s_fitting.freeStanding;
      },
      set(value) {
        this.updateFitting({
          freeStanding: value,
          fitted: !value,
        });
      },
    },
    excluded: {
      get() {
        return this.s_fitting.excluded;
      },
      set(value) {
        this.updateFitting({
          excluded: value,
        });
      },
    },
    price: {
      get() {
        return this.s_fitting.price;
      },
      set(value) {
        this.updateFitting({
          price: value,
        });
      },
    },
    comments: {
      get() {
        return this.s_fitting.comments;
      },
      set(value) {
        this.updateFitting({
          comments: value,
        });
      },
    },
  },
};
</script>

<template>
  <ValidationObserver v-if="this.s_fitting" v-slot="{ errors, invalid }">
    <div class="row d-flex align-items-center border-top pt-2">
      <div class="col-sm-12 col-md-6">
        <div class="d-flex justify-content-start mobile-padding">
          <h5 class="d-inline-block d-md-none pr-2">Name:</h5>
          <h5 class="w-75 text-truncate text-capitalize">{{ title }}</h5>
        </div>
      </div>

      <div class="col-sm-12 col-md-1">
        <div class="d-flex justify-content-start mobile-padding">
          <h5 class="d-inline-block d-md-none pr-2">Fitted:</h5>
          <b-form-checkbox class="border-0" name="check-button" v-model="fitted" switch />
        </div>
      </div>

      <div class="col-sm-12 col-md-1">
        <div class="d-flex justify-content-start mobile-padding">
          <h5 class="d-inline-block d-md-none pr-2">Excluded:</h5>
          <b-form-checkbox v-model="excluded" name="check-button-excluded" class="border-0" switch />
        </div>
      </div>

      <div class="col-sm-12 col-md-1">
        <ValidationProvider
          :rules="{ required_if: typeof excluded !== 'undefined' && excluded !== null && excluded }"
          :name="`${title} price`"
          :immediate="true"
          v-slot="{ errors }"
        >
          <div class="d-flex justify-content-start mobile-padding">
            <h5 class="d-inline-block d-md-none pr-2">Price:</h5>
            <input v-model="price" style="min-width: 60px" class="form-control mb-0" placeholder="Price" />
          </div>
        </ValidationProvider>
      </div>

      <div class="col-sm-12 col-md-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-10 pl-0 d-flex justify-content-start pr-0 mobile-padding">
              <h5 class="d-inline-block d-md-none pr-2">Comments:</h5>
              <input v-model="comments" class="form-control mb-0" style="min-width: 90px" placeholder="Comments" />
            </div>

            <div class="col-md-2 d-flex justify-content-center justify-content-md-start mobile-padding">
              <button type="button" class="btn btn-danger" @click="removeFitting">
                <i class="icon-trash-alt"></i>
                <span class="d-inline-block d-md-none pl-2">Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-8" />
        <div class="col-md-4">
          <span class="form-text text-danger" v-for="(value, key) in errors" :key="key">{{ value[0] }}</span>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss" scoped>
.mobile-padding {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
