<script>
import FormWrapper from '@/components/FormWrapper';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Rooms from '@/components/forms/Rooms';
import Fittings from '@/components/forms/Fittings';
import PdfPreview from '@/components/forms/SalePDFpreview';
import { mapActions, mapState } from 'vuex';
import PostTrackingEvent from '@/api/trackingEvents/postTrackingEvents';
import Modal from '@/components/Modal';
import SummaryPage from '@/components/forms/SummaryPage';
import AboutTA10 from '@/components/forms/AboutPages/AboutTA10';
import PropertyAndClientDetails from '@/components/forms/PropertyAndClientDetails';
import patchTa10Submitted from '@/api/sales/patchTa10Submitted';
import SaveProgressInfo from '@/components/messaging/SaveProgressInfo';
import FormCompletion from '@/mixins/FormCompletion';

export default {
  name: 'TA10',
  components: {
    FormWrapper,
    FormWizard,
    TabContent,
    WizardButton,
    Rooms,
    Fittings,
    PdfPreview,
    Modal,
    SummaryPage,
    AboutTA10,
    PropertyAndClientDetails,
    SaveProgressInfo,
  },
  mixins: [FormCompletion],
  props: ['entityType', 'entityId'],
  methods: {
    ...mapActions('sale', ['addTrackingEvent', 'setEventToHandle']),
    setLoading(value) {
      this.wizardLoading = value;
    },
    updateRooms(value) {
      this.rooms = value;
    },
    saveRooms() {
      // Triggered by 'before change' event to save rooms into API before loading fittings
      return this.$refs.addRooms.showFittings(this.continueWithDefaultRooms);
    },
    saveFittings(index) {
      this.$refs[`room${index}`][0].saveToApi();
      return true;
    },
    goToFittingsPage() {
      /*
      This method has been triggered by modal button event what
      means that the user is happy to continue with default rooms
      */
      this.continueWithDefaultRooms = true;
      this.$refs.wizard.nextTab();
    },
    checkConfirmation: function () {
      return this.$refs.aboutTa10.confirm();
    },
    showPdfPreview: async function (downloadFile = false) {
      await this.saveFittings(this.rooms.length - 1);
      setTimeout(() => this.$refs.pdfPreview.getPdf(downloadFile, 'ta10-form-'), 2000);
    },
    saveAndRedirect: async function () {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
    saveAndShowModalBeforeSubmit: async function () {
      this.showSubmitModal = true;
    },
    async submitForm(value) {
      this.showSubmitModal = false;
      if (value) {
        this.disabled = true;

        let metadata = '';
        let eventSlug = 'completed_ta10';
        let trackingEvent = {
          product_slug: this.entityType,
          entity_id: this.entityId,
          event_slug: eventSlug,
          metadata: metadata,
        };

        await Promise.all([
          PostTrackingEvent(trackingEvent),
          patchTa10Submitted({
            id: this.entityId,
          }),
        ])
          .then(() => {
            this.addTrackingEvent(trackingEvent);
            this.$gtag.event('Completed Milestone', {
              event_category: 'Task',
              event_label: 'Completed TA10',
            });

            this.setEventToHandle('completed_ta10');

            this.navigate('ViewEntity', {
              entityType: this.entityType,
              entityId: this.entityId,
            });
          })
          .catch((err) => console.debug(err));

        this.disabled = false;
      }
    },
    scrollToTop() {
      if (this.$router.currentRoute.name === 'ViewEntity') {
        this.$scrollTo('.page-header');
      }
    },
  },
  data() {
    return {
      rooms: [],
      slotProps: false,
      showModal: true,
      continueWithDefaultRooms: false,
      showSubmitModal: false,
      wizardLoading: false,
      disabled: false,
    };
  },
  computed: {
    ...mapState({
      buildingId: (state) => state.building.buildingId,
    }),
    url: function () {
      return `${process.env.VUE_APP_API_ENDPOINT}/buildings/${this.buildingId}/generate-pdf/ta10-form`;
    },
  },
};
</script>

<template>
  <form-wrapper :entityType="entityType" :entityId="entityId" class="mb-5">
    <div class="home" slot="form-content">
      <form-wizard
        title=""
        subtitle=""
        :color="$t('site.formColour')"
        errorColor="#F44336"
        stepSize="sm"
        ref="wizard"
        @on-loading="setLoading"
        @on-change="scrollToTop"
      >
        <tab-content title="Details">
          <property-and-client-details />
          <save-progress-info />
        </tab-content>

        <tab-content title="About" :before-change="checkConfirmation">
          <AboutTA10 ref="aboutTa10" :entityType="entityType" :entityId="entityId" />
        </tab-content>

        <tab-content title="Add Rooms" :before-change="saveRooms">
          <rooms ref="addRooms" @updateRooms="updateRooms" @goToFittingsPage="goToFittingsPage"></rooms>
        </tab-content>

        <tab-content
          v-for="(item, key) in rooms"
          :key="key"
          :title="item.title"
          :before-change="() => saveFittings(key)"
        >
          <fittings
            v-if="item.roomId"
            :ref="`room${key}`"
            :key="item.roomId"
            :selected="item.roomType"
            :roomName="item.title"
            :roomId="item.roomId"
          ></fittings>
        </tab-content>

        <tab-content title="Summary">
          <summary-page
            :disabled="disabled"
            :entityType="entityType"
            ref="summary"
            @preview="showPdfPreview"
            @download="showPdfPreview(true)"
            @save="saveAndRedirect"
            @submit="saveAndShowModalBeforeSubmit"
          ></summary-page>
        </tab-content>
        <!-- Customised Buttons -->
        <template slot="footer" slot-scope="props" v-bind="slotProps">
          <div class="wizard-footer-left">
            <wizard-button
              v-if="props.activeTabIndex > 0"
              class="w-100 w-sm-auto bg-light border-dashed text-primary"
              @click.native="props.prevTab()"
              :style="props.fillButtonStyle"
              >Previous
            </wizard-button>
          </div>
          <div class="wizard-footer-right">
            <div v-if="!props.isLastStep">
              <wizard-button
                @click.native="props.nextTab()"
                class="wizard-footer-right w-100 w-sm-auto"
                :style="props.fillButtonStyle"
                ref="nextButton"
                :disabled="wizardLoading"
              >
                Save and Continue
                <i v-if="wizardLoading" class="icon-spinner11 ml-2 spinner"></i>
                <i v-else class="icon-arrow-right5 ml-2"></i>
              </wizard-button>
            </div>
            <div v-else>
              <pdf-preview
                form-name="TA10"
                ref="pdfPreview"
                :entityType="entityType"
                :entityId="entityId"
              ></pdf-preview>
              <modal
                v-if="showSubmitModal"
                closeButtonText="Close"
                proceedButtonText="Save And Submit"
                @proceed="submitForm"
                >Be aware that once you "Save And Submit", the form will be locked for any changes. Do you want submit
                this form?
              </modal>
            </div>
          </div>
        </template>
      </form-wizard>
    </div>
  </form-wrapper>
</template>
