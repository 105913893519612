<script>
export default {
  name: 'RoomBlock',
  props: {
    rooms: {
      type: Array,
      required: true,
    },
    deletable: {
      type: Boolean,
    },
  },
  methods: {
    removeRoom(room) {
      this.$emit('removeRoom', room);
    },
  },
};
</script>

<template>
  <div class="row">
    <div v-for="(room, index) in rooms" :key="index" class="col-md-3">
      <div class="room card bg-light my-1">
        <div class="room-body text-truncate pl-3">
          <div class="room-body-text">
            {{ room.title }}
          </div>
        </div>
        <div v-if="deletable" class="close close-button" @click="removeRoom(room)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.close-button {
  position: absolute;
  right: 8px;
  margin-top: 5px;
  cursor: pointer;
}

.room {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.02);

  &:hover {
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.04);
    transform: translate(0, -6px);
    transition-delay: 0s !important;
  }

  &.full {
    width: 100%;
    text-align: center;
  }
}

.room-body {
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.room-body-text {
  width: 175px;
}
</style>
